<div class="main-banner item-bg-one jarallax"  data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <h4>Nuestro objetivo</h4>
                    <h1>Brindamos a <span>nuestros clientes</span> un servicio integral con los más altos estándares de calidad.</h1>
                    <p>Trabajamos para cuidar su patrimonio y consolidar su futuro.</p>
                    <a href="#welcome" class="btn btn-primary">Empezar</a>
                    <a href="#work" class="btn btn-primary view-work">Ver portafolio</a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-welcome></app-welcome>

<app-about></app-about>

<app-strategy></app-strategy>

<app-who-we-are></app-who-we-are>

<app-cta></app-cta>

<app-skill></app-skill>

<app-funfacts></app-funfacts>

<app-team></app-team>

<app-how-we-work></app-how-we-work>

<app-services></app-services>

<app-why-we-different></app-why-we-different>

<app-work></app-work>

<!-- <app-pricing></app-pricing> -->

<app-faq></app-faq>

<app-feedback></app-feedback>

<!-- COmento el blog que para esta caso no me sirve -->
<!-- <app-blog></app-blog> -->

<app-partner></app-partner>

<!-- Comento esto por que no lo utilizaremos -->
<!-- <app-subscribe></app-subscribe> -->

<app-contact></app-contact>
<section id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Acerca de <span>Asesores de Negocio BC</span></h2>
            <p>Somos un grupo de empresas independientes pero unidas en asociaciones estratégicas para brindar a nuestros clientes una amplia gama de posibilidades, soluciones y alternativas de crecimiento.</p>
        </div>
        
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>Digitalizamos <span>tu negocio</span></h2>
                        <p>¿Te imaginas que tu producto o servicio pueda ser visto por decenas de miles clientes pontenciales en tu alcaldia o región? .</p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Diseño creativon</li>
                        <li><i class="fas fa-check"></i>Retina Ready</li>
                        <li><i class="fas fa-check"></i>Diseño Responsivo</li>
                        <li><i class="fas fa-check"></i>Diseño Moderno</li>
                        <li><i class="fas fa-check"></i>Diseño impresionante</li>
                        <li><i class="fas fa-check"></i>Branding y marketing digital</li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
            
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/img/hotmaxplay.png" alt="about">
                    
                    <div class="video-btn">
                        <a href="https://www.youtube.com/watch?v=XbU1lyv5dY0" class="popup-youtube"><i class="fas fa-play"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="team" class="team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <!-- <h4>Conoce a Asesores de Negocio BC</h4> -->
            <h2>Cono a los integrantes del equipo de <span>Asesores de Negocio BC</span></h2>
            <p>Los logros de una organización son los resultados del esfuerzo combinado de cada individuo.</p>
        </div>
        
        <div class="team-slides owl-carousel owl-theme">
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-1.jpg" alt="team-img">
                </div>

                <div class="content">
                    <h3 class="title">Cosme Cruz</h3>
                    <span class="post">CEO</span>
                </div>

                <div class="social">
                    <ul>
                        <li><a href="#" class="fab fa-facebook-f"></a></li>
                        <li><a href="#" class="fab fa-twitter"></a></li>
                        <li><a href="#" class="fab fa-instagram"></a></li>
                        <li><a href="#" class="fab fa-linkedin-in"></a></li>
                    </ul>
                </div>
            </div>
        
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-2.jpg" alt="team-img">
                </div>

                <div class="content">
                    <h3 class="title">Antonio Contreras</h3>
                    <span class="post">Web Developer</span>
                </div>

                <div class="social">
                    <ul>
                        <li><a href="#" class="fab fa-facebook-f"></a></li>
                        <li><a href="#" class="fab fa-twitter"></a></li>
                        <li><a href="#" class="fab fa-instagram"></a></li>
                        <li><a href="#" class="fab fa-linkedin-in"></a></li>
                    </ul>
                </div>
            </div>
        
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-3.jpeg" alt="team-img">
                </div>

                <div class="content">
                    <h3 class="title">Antonio Palacio</h3>
                    <span class="post">Back-End Developer</span>
                </div>
                
                <div class="social">
                    <ul>
                        <li><a href="#" class="fab fa-facebook-f"></a></li>
                        <li><a href="#" class="fab fa-twitter"></a></li>
                        <li><a href="#" class="fab fa-instagram"></a></li>
                        <li><a href="#" class="fab fa-linkedin-in"></a></li>
                    </ul>
                </div>
            </div>
        
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-4.jpg" alt="team-img">
                </div>

                <div class="content">
                    <h3 class="title">Antonio Zepeda</h3>
                    <span class="post">Front-End Developer</span>
                </div>

                <div class="social">
                    <ul>
                        <li><a href="#" class="fab fa-facebook-f"></a></li>
                        <li><a href="#" class="fab fa-twitter"></a></li>
                        <li><a href="#" class="fab fa-instagram"></a></li>
                        <li><a href="#" class="fab fa-linkedin-in"></a></li>
                    </ul>
                </div>
            </div>
        
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-5.jpg" alt="team-img">
                </div>

                <div class="content">
                    <h3 class="title">Antonio Bolaños</h3>
                    <span class="post">Web Developer</span>
                </div>

                <div class="social">
                    <ul>
                        <li><a href="#" class="fab fa-facebook-f"></a></li>
                        <li><a href="#" class="fab fa-twitter"></a></li>
                        <li><a href="#" class="fab fa-instagram"></a></li>
                        <li><a href="#" class="fab fa-linkedin-in"></a></li>
                    </ul>
                </div>
            </div>
        
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-6.jpg" alt="team-img">
                </div>

                <div class="content">
                    <h3 class="title">Olivia Smith</h3>
                    <span class="post">Photographer</span>
                </div>
                
                <div class="social">
                    <ul>
                        <li><a href="#" class="fab fa-facebook-f"></a></li>
                        <li><a href="#" class="fab fa-twitter"></a></li>
                        <li><a href="#" class="fab fa-instagram"></a></li>
                        <li><a href="#" class="fab fa-linkedin-in"></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</section>
<section class="funfacts-area pt-100 pb-70 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="far fa-smile"></i>
                    <h3><span class="odometer" data-count="540">00</span></h3>
                    <p>Clientes Felices</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-shield-alt"></i>
                    <h3><span class="odometer" data-count="980">00</span></h3>
                    <p>Proyectos Exitosos</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-award"></i>
                    <h3><span class="odometer" data-count="46">00</span></h3>
                    <p>Premios Ganados</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-users"></i>
                    <h3><span class="odometer" data-count="50">00</span></h3>
                    <p>Integrantes de Asesore de Negocio BC</p>
                </div>
            </div>
        </div>
    </div>
</section>
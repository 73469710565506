<section id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <!-- <h4>Ponerse en contacto</h4> -->
            <h2> <span>Contáctenos</span> </h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form>
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Nombre</label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="email" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Número Celular</label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Mensaje</label>
                                    <textarea class="form-control" cols="30" rows="4"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">Enviar Mensaje</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">                
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li><i class="fas fa-map-marker-alt"></i> <span>Estamos en:</span>Exercisplan 4, 111 49 Stockholm, Sweden</li>
                                <li><i class="far fa-envelope"></i> <a href="mailto:contacto@asesoresdenegociobc.com"><span>Email:</span>contacto@asesoresdenegociobc.com</a></li>
                                <li><i class="fas fa-phone-alt"></i> <a href="tel:1244122445515"><span>Llamanos al:</span>(+124)412-2445515</a></li>
                                <!-- <li><i class="fas fa-fax"></i> <a href="tel:61724160055"><span>Fax:</span>+617-241-60055</a></li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="work" class="work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nuestro trabajo nos <span>respalda</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        
        <div class="shorting-menu">
            <button class="filter" data-filter="all">Todos</button>
            <button class="filter" data-filter=".brand">Contable</button>
            <button class="filter" data-filter=".design">Legal</button>
            <button class="filter" data-filter=".graphic">Administrativo</button>
            <button class="filter" data-filter=".photoshop">Tecnología de la Información</button>
            <button class="filter" data-filter=".illustrator">Consultoría</button>
        </div>
    </div>
       
    <div class="container-fluid">
        <div class="shorting">
            <div class="row">
                <div class="col-lg-4 col-md-6 mix brand design">
                    <div class="single-work">
                        <img src="assets/img/work-img1.jpg" alt="work-img">
                        
                        <div class="work-content">
                            <h3>Creative Design</h3>
                            <ul>
                                <li><a href="#">Design</a></li>
                                <li> . </li>
                                <li><a href="#">Brand</a></li>
                            </ul>
                        </div>
                        
                        <a href="assets/img/work-img1.jpg" class="popup-btn"><i class="fas fa-plus"></i></a>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 mix design graphic">
                    <div class="single-work">
                        <img src="assets/img/work-img2.jpg" alt="work-img">
                        
                        <div class="work-content">
                            <h3>Games Design</h3>
                            <ul>
                                <li><a href="#">IT</a></li>
                                <li> . </li>
                                <li><a href="#">Games</a></li>
                            </ul>
                        </div>
                        
                        <a href="assets/img/work-img2.jpg" class="popup-btn"><i class="fas fa-plus"></i></a>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 mix graphic photoshop">
                    <div class="single-work">
                        <img src="assets/img/work-img3.jpg" alt="work-img">
                        
                        <div class="work-content">
                            <h3>Creative Design</h3>
                            <ul>
                                <li><a href="#">Design</a></li>
                                <li>.</li>
                                <li><a href="#">Brand</a></li>
                            </ul>
                        </div>
                        
                        <a href="assets/img/work-img3.jpg" class="popup-btn"><i class="fas fa-plus"></i></a>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 mix photoshop illustrator">
                    <div class="single-work">
                        <img src="assets/img/work-img4.jpg" alt="work-img">
                        
                        <div class="work-content">
                            <h3>Creative Design</h3>
                            <ul>
                                <li><a href="#">Design</a></li>
                                <li>.</li>
                                <li><a href="#">Brand</a></li>
                            </ul>
                        </div>
                        
                        <a href="assets/img/work-img4.jpg" class="popup-btn"><i class="fas fa-plus"></i></a>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 mix illustrator">
                    <div class="single-work">
                        <img src="assets/img/work-img5.jpg" alt="work-img">
                        
                        <div class="work-content">
                            <h3>Creative Design</h3>
                            <ul>
                                <li><a href="#">Design</a></li>
                                <li>.</li>
                                <li><a href="#">Brand</a></li>
                            </ul>
                        </div>
                        
                        <a href="assets/img/work-img5.jpg" class="popup-btn"><i class="fas fa-plus"></i></a>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 mix brand">
                    <div class="single-work">
                        <img src="assets/img/work-img6.jpg" alt="work-img">
                        
                        <div class="work-content">
                            <h3>Creative Design</h3>
                            <ul>
                                <li><a href="#">Design</a></li>
                                <li>.</li>
                                <li><a href="#">Brand</a></li>
                            </ul>
                        </div>
                        
                        <a href="assets/img/work-img6.jpg" class="popup-btn"><i class="fas fa-plus"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
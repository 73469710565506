<section id="portafolio" class="why-we-different ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>¿Cuáles son nuestros <span> servicios?</span></h2>
            <p>No basamos nuestra estrategias digitales en los medios tradicionales conocidos hasta el 2020.</p>
        </div>
       
        <div class="tabset why-we-different-tabset">
            <!-- Tab 1 -->
            <input type="radio" name="tabset" id="tab1" aria-controls="speed-flexibility" checked>
            <label for="tab1">Contable</label>
            <!-- Tab 2 -->
            <input type="radio" name="tabset" id="tab2" aria-controls="profeesional-work">
            <label for="tab2">Legal</label>
            <!-- Tab 3 -->
            <input type="radio" name="tabset" id="tab3" aria-controls="experienced-staff">
            <label for="tab3">Administración</label>
            <!-- Tab 4 -->
            <input type="radio" name="tabset" id="tab4" aria-controls="custom-support">
            <label for="tab4">Tecnología de Información</label>

            <div class="tab-panels">
                <section id="speed-flexibility" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/equipo.jpg" alt="img">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Contable</h3>
                                <p>Proporcionamos los servicios de contabilidad, regularizaciones y depuraciones de Personas Físicas y Morales.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Contabilidad</li>
                                    <li><i class="fas fa-check"></i> Auditoría</li>
                                    <li><i class="fas fa-check"></i> Fiscal</li>
                                    <li><i class="fas fa-check"></i> Financiera</li>                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section id="profeesional-work" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/Legal.jpg" alt="img">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Legal</h3>
                                <p>Dentro del área legal ofrecemos la  elaboración, reestructura y/o regularización de los documentos necesarios que requieren los accionistas.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Fiscal</li>
                                    <li><i class="fas fa-check"></i> Coorporativo</li>
                                    <li><i class="fas fa-check"></i> Mercantil</li>
                                    <li><i class="fas fa-check"></i> Laboral</li>
                                    <li><i class="fas fa-check"></i> Patrimonial</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section id="experienced-staff" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/admon.jpg" alt="img">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Administración</h3>
                                <p>Revisar texto.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Administración</li>
                                    <li><i class="fas fa-check"></i> Operacional</li>
                                    <li><i class="fas fa-check"></i> Recursos Humanos</li>
                                    <li><i class="fas fa-check"></i> Mercadoctenia</li>
                                    <li><i class="fas fa-check"></i> Capacitación</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section id="custom-support" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/ti.jpeg" alt="img">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Tecnología de Información</h3>
                                <p>Hemos desarrollado alianzas estrategicas con un objetivo comun, que es dar a nuestros clientes las alternativas mas eficientes y economicas en el mundo digital.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Integración tecnológica</li>
                                    <li><i class="fas fa-check"></i> Servicios de Consultoría</li>
                                    <li><i class="fas fa-check"></i> Servicio de Desarrollo de Software</li>
                                    <li><i class="fas fa-check"></i> Servicio de Business Intelligence (BI)</li>     
                                    <li><i class="fas fa-check"></i> Servicio de Business Process Manager (BPM)</li>                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>
<section class="cta-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="cta-content">
            <div class="section-title mb-0">
                <h4>¿Busca servicios exclusivos?</h4>
                <h2>Obtenga lo mejor para su negocio</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <a href="#contact" class="btn btn-primary">Contactanos</a>
                <a href="#work" class="btn btn-primary view-work">Ve nuestro trabajo</a>
            </div>
        </div>
    </div>
</section>
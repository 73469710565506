<div class="home-slides owl-carousel owl-theme">
    <div class="main-banner item-bg-two">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-text">
                        <h4>We Are Creative</h4>
                        <h1>Nice To <span>Meet</span> You</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <a href="#about" class="btn btn-primary">Get Started</a>
                        <a href="#work" class="btn btn-primary view-work">View Work</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="main-banner item-bg-one">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-text">
                        <h4>We Are Professional</h4>
                        <h1>Best <span>Digital</span> Experience</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <a href="#about" class="btn btn-primary">Get Started</a>
                        <a href="#work" class="btn btn-primary view-work">View Work</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="main-banner item-bg-three">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-text">
                        <h4>We Are Designer</h4>
                        <h1>Creativity is  <span>Intelligence</span></h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <a href="#about" class="btn btn-primary">Get Started</a>
                        <a href="#work" class="btn btn-primary view-work">View Work</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-about></app-about>

<app-strategy></app-strategy>

<app-who-we-are></app-who-we-are>

<app-cta></app-cta>

<app-skill></app-skill>

<app-funfacts></app-funfacts>

<app-team></app-team>

<app-how-we-work></app-how-we-work>

<app-services-two></app-services-two>

<app-why-we-different></app-why-we-different>

<app-work></app-work>

<app-pricing></app-pricing>

<app-faq></app-faq>

<app-feedback></app-feedback>

<app-blog></app-blog>

<app-partner></app-partner>

<app-subscribe></app-subscribe>

<app-contact></app-contact>
<section class="feedback-area ptb-100">
    <div class="container">
        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-item">
                <p>“Si hice una inversión que realmente valió la pena. Me encuentro sastifecho con la calidad de los servicios y los proyectos que obtengo de Asesores de Negocio BC.”</p>

                <div class="info">
                    <h3>Roberto Palacios</h3>
                    <span>CIO MerenguePlus</span>
                    <img src="assets/img/Rostro1.jpg" class="shadow rounded-circle" alt="image">
                </div>
            </div>

            <div class="single-feedback-item">
                <p>“No tengo la menor duda de que sin Asesores de Negocio BC no habría podido dar el salto a (De la vista nace el amor), una agencia digital que comencé en 2014. El trabajo que obtuve a través de Asesores de Negocio BC me permitió tener algo sobre lo que construir. Ahora tenemos alrededor de 45 personas en nuestro equipo, muchas de las cuales encontramos y reclutamos a través de  Asesores de Negocio BC.”</p>

                <div class="info">
                    <h3>Jaime Cortes</h3>
                    <span>CEO de la vista nace el amor</span>
                    <img src="assets/img/Rostro2.jpg" class="shadow rounded-circle" alt="image">
                </div>
            </div>

            <div class="single-feedback-item">
                <p>“Solo Asesores de Negocio BC ayudó a hacer crecer nuestra empresa de dos hombres a un equipo de 14 en solo un par de años. Y también fue responsable de presentarnos algunos clientes estelares y pares de la industria. Fácilmente, la mitad de nuestra producción reciente se puede atribuir de alguna manera. Solo el año pasado, los clientes potenciales representaron $ 800k de nuestros ingresos.”</p>

                <div class="info">
                    <h3>Pedro Lopez</h3>
                    <span>Director de Finanzas</span>
                    <img src="assets/img/Rostro3.jpeg" class="shadow rounded-circle" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
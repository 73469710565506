<section id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <!-- <h4>Nosotros somos creativos</h4> -->
            <h2>Bienvenido a <span>Asesores de Negocio BC</span></h2>
            <p>Proveemos de los servicios necesarios favoreciendo el desarrollo corporativo de forma integral, participando activamente en la consolidación del futuro de nuestros clientes, colaboradores y usuarios en general, ofreciéndoles una nueva filosofía del quehacer empresarial acorde al crecimiento de las áreas funcionales de toda empresa que requiera nuestra cooperación, para así ser partícipes de los constantes cambios del mundo actual.</p>
        </div>
        
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-edit"></i>
                    </div>
                    <h3>Servicio contable</h3>
                    <p>Lorem ipsum dolor sit amet sit ipsum, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                    <a href="#about" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-laptop"></i>
                    </div>
                    <h3>Servicio legal</h3>
                    <p>Lorem ipsum dolor sit amet sit ipsum, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                    <a href="#about" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-life-ring"></i>
                    </div>
                    <h3>Servicio administrativo</h3>
                    <p>Lorem ipsum dolor sit amet sit ipsum, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                    <a href="#about" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-life-ring"></i>
                    </div>
                    <h3>Servicio tecnología de la Información</h3>
                    <p>Contamos con servicios de consultoria en el área de TI, con expertos en la materia .</p>
                    <a href="#about" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>

        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</section>
<section id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nuestros <span>Valores</span></h2>
            <p>Nuestras propuestas y acciones van encaminadas a satisfacer necesidades apremiantes del diario vivir, garantizando la consolidación del presente y la exitosa permanencia en el futuro, centralizadas en cuatro valores corporativos, que son los cuatro ejes que rigen nuestras acciones y propuestas.</p>
        </div>
       
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img1.jpg" alt="services-img">
                        
                        <div class="icon">
                            <i class="fas fa-pencil-alt"></i>
                        </div>
                    </div>
                    
                    <div class="services-content">
                        <h3>Integridad</h3>
                        <p>Todas nuestras acciones siempre serán acordes con las estrategias que ofrecemos, la congruencia y la capacidad guían los pasos hacia el éxito de nuestros clientes y colaboradores.</p>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img2.jpg" alt="services-img">
                        
                        <div class="icon">
                            <i class="fab fa-linode"></i>
                        </div>
                    </div>
                    
                    <div class="services-content">
                        <h3>Fidelidad</h3>
                        <p>Centramos nuestros esfuerzos en alcanzar el éxito de nuestros clientes a través del interés constante, del servicio colaborativo y la consolidación de las acciones. El éxito de nuestros clientes  es nuestro éxito.</p>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img3.jpg" alt="services-img">
                        
                        <div class="icon">
                            <i class="fas fa-desktop"></i>
                        </div>
                    </div>
                    
                    <div class="services-content">
                        <h3>Perseverancia</h3>
                        <p>Siempre buscaremos las mejores opciones para que nuestros clientes obtengan los mejores resultados.</p>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img4.jpg" alt="services-img">
                        
                        <div class="icon">
                            <i class="fas fa-chart-line"></i>
                        </div>
                    </div>
                    
                    <div class="services-content">
                        <h3>Positivismo</h3>
                        <p>Proporcionamos nuevas alternativas generando opciones de crecimiento, encontrando un camino más viable, acorde a los recursos, necesidades y circunstancias bajo las cuales se encuentran nuestros clientes.</p>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img5.jpg" alt="services-img">
                        
                        <div class="icon">
                            <i class="fas fa-anchor"></i>
                        </div>
                    </div>
                    
                    <div class="services-content">
                        <h3>Honestidad</h3>
                        <p>Transparencia en los procesos para cumplir con lo acordado..</p>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img6.jpg" alt="services-img">
                        
                        <div class="icon">
                            <i class="fas fa-headphones-alt"></i>
                        </div>
                    </div>
                    
                    <div class="services-content">
                        <h3>Disciplina</h3>
                        <p>Enfocar los recursos para lograr los objetivos planteados.</p>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img7.jpg" alt="services-img">
                        
                        <div class="icon">
                            <i class="fas fa-mobile-alt"></i>
                        </div>
                    </div>
                    
                    <div class="services-content">
                        <h3>Compromiso</h3>
                        <p>Cumplir conscientemente dentro del tiempo estipulado.</p>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img8.jpg" alt="services-img">
                        
                        <div class="icon">
                            <i class="fas fa-camera-retro"></i>
                        </div>
                    </div>
                    
                    <div class="services-content">
                        <h3>Profesionalismo</h3>
                        <p>Actuar con respeto, ética, formalidad y calidad.</p>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img9.jpg" alt="services-img">
                        
                        <div class="icon">
                            <i class="far fa-life-ring"></i>
                        </div>
                    </div>
                    
                    <div class="services-content">
                        <h3>Pasión</h3>
                        <p>Hacer todo con un alto nivel de excelencia.</p>
                    </div>
                </div>
            </div>

            <!-- Agrego nuevas columnas de servicios -->

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/proye.jpg" alt="services-img">
                        
                        <div class="icon">
                            <i class="fas fa-pencil-alt"></i>
                        </div>
                    </div>
                    
                    <div class="services-content">
                        <h3>Colaboración</h3>
                        <p>Ser un aliado para nuestros clientes, maximizando su satisfacción y rentabilidad.</p>
                    </div>
                </div>
            </div>                        
            
            <!-- FIn de otros servicios -->



        </div>
    </div>
</section>
<nav class="navbar navbar-expand-lg navbar-light bg-light header-sticky">
    <div class="container">
        <a class="navbar-brand" routerLink="/"><span> </span> <a class="verde">ASESORES DE NEGOCIO</a> <a class="rojo">BC</a> </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><a class="nav-link active" href="#home">Inicio</a></li>
                <li class="nav-item"><a class="nav-link" href="#about">Nosotros</a></li>
                <li class="nav-item"><a class="nav-link" href="#team">Equipo</a></li>
                <li class="nav-item"><a class="nav-link" href="#portafolio">Servicios</a></li>
                <li class="nav-item"><a class="nav-link" href="#work">Nuestro Trabajo</a></li>
                <!-- <li class="nav-item"><a class="nav-link" href="#pricing">Precios</a></li> -->
                <!-- <li class="nav-item"><a class="nav-link" href="#blog">Blog</a></li> -->
                <li class="nav-item"><a class="nav-link" href="#contact">Contáctanos</a></li>
            </ul>
        </div>
    </div>
</nav>